module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://staging.opsio.se"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"220d4e900e95719dcdf8ec9818b1bc29"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@lee-wfaa/gatsby-plugin-password-protect/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true,"password":"password!@2024ops","publicKey":"MO|-#l mdadra2#4v%q^&-IHgA0,,aWF-sWYA{!/=jR]3{>SlX90Z-4SQ,F2xyX7<%X,/+","expiration":86400,"storageKey":"gppp","loadingMessage":"Authenticating","loginHeading":"This site is password protected","loginButtonText":"View site"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
